import { ref, watch, computed } from '@vue/composition-api'
import contentHelpRepository from '@/repository/contentHelpRepository'
import ability from '@/libs/acl/ability'
import store from '@/store'

export default function contentHelpList() {
  const refContentHelpTable = ref(null)

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const selectRules = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refContentHelpTable.value ? refContentHelpTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refContentHelpTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    contentHelpRepository.elementList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response
        if ((items || []).length === 0) {
          callback([])
        }

        callback(items.map(item => ({
          ...item,
          createdUser: !item.createdUser ? '' : `${item.createdUser.firstName || ''} ${item.createdUser.lastName}` || '',
          updatedUser: !item.updatedUser ? '' : `${item.updatedUser.firstName || ''} ${item.updatedUser.lastName}` || '',
        })))
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const clearSelectTable = () => {
    refContentHelpTable.value.clearSelected()
  }

  const selectRowTable = val => {
    selectRules.value = val
  }

  const updateElement = (id, active) => new Promise(resolve => {
    if (ability.can('update', 'ContentHelp')) {
      const param = new FormData()
      param.append('active', parseInt(active, 10) === 1 ? 0 : 1)
      contentHelpRepository.updateElement(id, param)
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          store.commit('app/SET_ERROR', error)
        })
    } else {
      store.commit('app/SET_ERROR', 'Недостаточно прав для изменения')
    }
  })

  const deleteElement = id => new Promise(resolve => {
    if (ability.can('delete', 'ContentHelp')) {
      contentHelpRepository.deleteElement(id)
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          store.commit('app/SET_ERROR', error)
        })
    } else {
      store.commit('app/SET_ERROR', 'Недостаточно прав для удаления')
    }
  })

  return {
    fetchItems,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refContentHelpTable,

    refetchData,
    clearSelectTable,
    selectRowTable,

    selectRules,
    updateElement,
    deleteElement,
  }
}
